<template>
  <div id="app">
    <div class="container">
      <div v-if="isConnected">
        <div>
          <span class="info-title">$AVAX Balance:&nbsp;</span>
          <span class="info-value">{{ avaxBalance }}</span>
          <img class="icon" width="24" :src="avaxIcon" />
        </div>
        <div>
          <span class="info-title">$SPRT Balance:&nbsp;</span>
          <span class="info-value">{{ spiritBalance }}</span>
          <img class="icon" width="24" :src="sprtIcon" />
        </div>
        <div>
          <span class="info-title">$SPRT Allowance:&nbsp;</span>
          <span class="info-value">{{ allowance ? '✔️ Granted' : '❌Not granted, please approve' }}</span>
          <button :disabled="allowance" @click="approveSpirit">APPROVE</button>
        </div>
        <div>
          <span class="info-title">$SPRT Claim Balance:&nbsp;</span>
          <span class="info-value">{{ claimBalance }}</span>
          <img class="icon" width="24" :src="sprtIcon" />
          <button :disabled="!canClaim" @click="claim">CLAIM</button>
          <div class="claim-gate" v-if="claimGated">
            ⏳ Claimable at {{ new Date((this.lastClaimDate * 1000) + (this.claimThreshold * 1000)).toLocaleString() }}
          </div>
        </div>
      </div>
      <div v-else style="text-align: center;">
          <span class="info-value">Connect your wallet to proceed...</span>
          <br /><br />
          <button @click="connect" style="margin: 0 auto;">CONNECT</button>
      </div>
    </div>
  </div>
</template>
<script>
import '@/assets/css/style.css'
import avaxIcon from '@/assets/images/avax-icon.png'
import sprtIcon from '@/assets/images/sprt-icon.png'
import { 
  initialize as initializeWallet, 
  connect as connectWallet, 
  isConnected,
  walletAddress,
  hasInfiniteAllowance, 
  queryAvaxBalance, 
  querySpiritBalance, 
  queryClaimBalance,
  queryLastClaimDate,
  queryClaimLock,
  approveSpirit,
  claim, 
  queryAllowedClaimInterval
} from "@/utils/web3";

export default {
  data() {
    return {
      walletAddress: undefined,
      isConnected: false,
      avaxIcon: avaxIcon,
      sprtIcon: sprtIcon,
      avaxBalance: 0,
      spiritBalance: 0,
      claimBalance: 0,
      lastClaimDate: 0,
      allowance: false,
      claimLock: false,
      canClaim: false,
      claimGated: false,
      claimThreshold: 60 * 60 * 24, // 1d locked after claim
    }
  },
  created() {
    this.$nextTick(() => {
      this.init()
    });
  },
  mounted() {
    
  },
  methods: {
    async init() {
      await initializeWallet()
      this.isConnected = isConnected()
      setTimeout(() => {
        this.isConnected = isConnected()
        if(this.isConnected) {
          this.connect()
        }
      }, 1000)
    },
    async connect() {
      await connectWallet()
      setTimeout(this.checkConnection, 1000)
    },
    async checkConnection() {
      this.isConnected = isConnected()
      if(this.isConnected) {
        this.updateData()
        setInterval(this.updateData, 5000)
      }
    },
    async updateData() {
      this.isConnected = isConnected();
      if(this.isConnected) {
        this.walletAddress = walletAddress();
        [
          this.avaxBalance, 
          this.spiritBalance, 
          this.claimBalance, 
          this.allowance, 
          this.lastClaimDate, 
          this.claimLock, 
          this.claimThreshold
        ] = await Promise.all([
          queryAvaxBalance(), 
          querySpiritBalance(), 
          queryClaimBalance(), 
          hasInfiniteAllowance(), 
          queryLastClaimDate(), 
          queryClaimLock(), 
          queryAllowedClaimInterval()
        ])
        this.claimGated = (Date.now() / 1000) - this.lastClaimDate <= this.claimThreshold
        this.canClaim = !this.claimLock && !this.claimGated && this.claimBalance > 0
        console.log(`Data updated!`);
      }
    },
    approveSpirit() {
      approveSpirit()
    },
    claim() {
      claim()
    }
  }
}
</script>

<style scoped>

@font-face {
  font-family: "The Ghost";
  src: local("The Ghost"), url(./assets/fonts/TheGhost.woff) format("woff"), url(./assets/fonts/TheGhost.ttf) format("truetype");
}

@font-face {
  font-family: "Spoky";
  src: local("Spoky"), url(./assets/fonts/Spoky.otf) format("opentype"), url(./assets/fonts/Spoky.ttf) format("truetype");
}

button {
  display: block;
  border-radius: 50vh;
  background-image: linear-gradient(90deg, #030b20 21%, #16104d 58%, #21277b 100%);
  font-family: "The Ghost";
  letter-spacing: 0.2rem;
  text-transform: uppercase;
  font-size: 1.4rem;
  padding-left: 28px;
  padding-right: 28px;
  padding-top: 20px;
  padding-bottom: 8px;
  color: #FFF;
  box-shadow: 0px 1px 2px rgba(255, 255, 255, 0.1);
  border: none;
  transition: all .05s ease-in-out;
  -webkit-transition: all .05s ease-in-out;
  transform: scale(1);
  margin-top: 16px;
  margin-bottom: 24px;
}

button:disabled {
  pointer-events: none;
  opacity: 0.5;
}

button:hover {
  box-shadow: 0px 2px 4px rgba(255, 255, 255, 0.2);
  -webkit-transform: scale(1.05);
  -moz-transform: scale(1.05);
  -o-transform: scale(1.05);
  transform: scale(1.05);
  cursor: pointer;
}

.container div span {
  font-family: "Spoky";
  text-transform: uppercase;
  letter-spacing: 0.2rem;
  font-size: 1.6rem;
}

.container .info-title {
  color: rgb(145, 145, 145);
}

.container .info-value {
  color: rgb(255, 255, 255);
}

#app {
  text-align: center;
}

.container {
  width: 36vw;
  background-color: rgba(0, 0, 0, 0.25);
  display: inline-block;
  margin: 96px auto;
  text-align: left;
  padding: 32px;
  border-radius: 16px;
}

.container div:nth-child(n+2) {
  margin-top: 64px;
}

.icon {
  display: inline-flex;
  vertical-align: bottom;
  margin-right: 16px;
  margin-left: 8px;
}

.claim-gate {
  display: inline;
  color: white;
}

@media screen and (max-width: 500px) {
  .container {
    width: 84vw;
    padding: 16px;
  }
}

</style>