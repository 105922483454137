import { ethers, utils } from 'ethers'

const { ethereum } = typeof window !== 'undefined' && window

const rpc = {
    name: 'Avalanche Mainnet C-Chain',
    chainId: "0x"+(43114).toString(16),
    url: 'https://api.avax.network/ext/bc/C/rpc',
    symbol: 'AVAX',
    explorer: 'https://snowtrace.io'
}

// const rpc = {
//     name: 'Avalanche Fuji Testnet',
//     chainId: "0x" + (43113).toString(16),
//     url: 'https://api.avax-test.network/ext/bc/C/rpc',
//     symbol: 'AVAX',
//     explorer: 'https://testnet.snowtrace.io'
// }

const spiritContract = {
    address: '0xf15609c283403123271cb266fc2ada15a1603879',
    abi: [
        "function allowance(address owner, address spender) external view returns(uint256)",
        "function approve(address spender, uint256 amount) external returns(bool)",
        "function balanceOf(address account) external view returns(uint256)"
    ]
}

const gameContract = {
    address: '0x45119414b31851c5003235b1119ebfd9e423c66e',
    abi: [
        "function claim() external",
        "function lastClaimTimestamp() external view returns(uint256)",
        "function claimLock() external view returns(bool)",
        "function claimBalance() external view returns(uint256)",
        "function allowedClaimInterval() external view returns(uint256)"
    ]
}

let provider

async function initialize() {

    if (isMetaMaskInstalled()) {

        provider = new ethers.providers.Web3Provider(ethereum, 'any')

        log("[WALLET]: Wallet is initialized")
        
        ethereum.on('accountsChanged', function (accounts) {
            // Time to reload your interface with accounts[0]!
            dispatchAccountUpdateEvent()
            if (accounts) {
                log("[WALLET]: Accounts changed " + accounts)
                loadPage()
            }
        })

        ethereum.on('chainChanged', function(chainId) {
            log("[WALLET]: Chain changed " + chainId)
            dispatchProviderResetEvent()
            if(chainId === rpc.chainId) {
                log("[WALLET]: Connection requested")
                connect()
            }
        })

        if(isConnected()) {
            await connect()
        }

    } else {
        log("MetaMask is not installed.")
    }

}

function isConnected() {
    if(ethereum) {
        return ethereum.selectedAddress !== null && ethereum.chainId === rpc.chainId
    } else {
        return false
    }
}

function walletAddress(mask = true) {
    const account = ethereum.selectedAddress
    if(account) {
        return mask ? maskWalletAddress(account) : account
    }
}

function maskWalletAddress(address) {
    return address.substring(0, 6) + "..." + address.substring(address.length-4, address.length)
}

async function connect(resetProvider) {

    if(!ethereum) {
        alert("MetaMask is not installed.")
        return
    }

    if(ethereum.chainId !== rpc.chainId) {
        log(`ethereum.chainId = ${ethereum.chainId} and rpc.chainId = ${rpc.chainId}`)
        log("[WALLET]: Wrong network!")
        await addNetworkToMetamask()
        return
    }

    if(!provider || resetProvider) {
        log("[WALLET]: Provider is reset")
        provider = new ethers.providers.Web3Provider(ethereum, 'any')
        dispatchProviderResetEvent()
    }

    const accounts = await ethereum.request({
        method: 'eth_requestAccounts'
    }).catch((error) => {
      if (error.code === 4001) {
        // EIP-1193 userRejectedRequest error
        loadPage()
      } else {
        log("[WALLET]: [connect]: " + error)
      }
    })

    if(accounts) {

        // log("[WALLET]: Block number is " + blockNumber)

        log("[WALLET]: Wallet connected")

        log("[WALLET]: Current account is " + ethereum.selectedAddress)
        
        dispatchAccountUpdateEvent()

    }

}

async function queryAvaxBalance() {
    if(!isConnected()) {
        return
    }
    const avaxBalance = await provider.getBalance(ethereum.selectedAddress)
    log(`$AVAX Balance is ${avaxBalance}`)
    return parseFloat(utils.formatEther(avaxBalance)).toFixed(2)
}

async function querySpiritBalance() {
    if(!isConnected()) {
        return
    }
    const contract = new ethers.Contract(spiritContract.address, spiritContract.abi, provider.getSigner())
    const spiritBalance = await contract.balanceOf(ethereum.selectedAddress)
    log(`$SPRT Balance is ${spiritBalance}`)
    return parseFloat(utils.formatEther(spiritBalance)).toFixed(2)
}

async function queryClaimBalance() {
    if(!isConnected()) {
        return
    }
    const contract = new ethers.Contract(gameContract.address, gameContract.abi, provider.getSigner())
    const claimBalance = await contract.claimBalance()
    log(`$SPRT Claim Balance is ${claimBalance}`)
    return parseFloat(utils.formatEther(claimBalance)).toFixed(2)
}

async function queryLastClaimDate() {
    if(!isConnected()) {
        return
    }
    const contract = new ethers.Contract(gameContract.address, gameContract.abi, provider.getSigner())
    const lastClaimDate = await contract.lastClaimTimestamp()
    log(`Last claim date is ${lastClaimDate}`)
    return lastClaimDate
}

async function queryClaimLock() {
    if(!isConnected()) {
        return
    }
    const contract = new ethers.Contract(gameContract.address, gameContract.abi, provider.getSigner())
    const claimLock = await contract.claimLock()
    log(`Claim lock is ${claimLock}`)
    return claimLock
}

async function queryAllowance() {
    if(!isConnected()) {
        return
    }
    const contract = new ethers.Contract(spiritContract.address, spiritContract.abi, provider.getSigner())
    const allowance = await contract.allowance(ethereum.selectedAddress, gameContract.address)
    log(`$SPRT Allowance is ${allowance}`)
    return allowance
}

async function queryAllowedClaimInterval() {
    if(!isConnected()) {
        return
    }
    const contract = new ethers.Contract(gameContract.address, gameContract.abi, provider.getSigner())
    const allowedClaimInterval = await contract.allowedClaimInterval()
    log(`Allowed $SPRT claim interval is ${allowedClaimInterval}`)
    return allowedClaimInterval
} 

async function approveSpirit() {
    if(!isConnected()) {
        return
    }
    const contract = new ethers.Contract(spiritContract.address, spiritContract.abi, provider.getSigner())
    await contract.approve(gameContract.address, ethers.constants.MaxUint256)
}

async function claim() {
    if(!isConnected()) {
        return
    }
    const contract = new ethers.Contract(gameContract.address, gameContract.abi, provider.getSigner())
    await contract.claim()
}

async function hasInfiniteAllowance() {
    if(!isConnected()) {
        return
    }
    const allowance = await queryAllowance()
    return allowance >= 100000000000000000000
    // return ethers.constants.MaxUint256.eq(allowance)
}

async function addNetworkToMetamask() {
    try {
        await ethereum.request({
            method: 'wallet_switchEthereumChain',
            params: [{ chainId: rpc.chainId }],
        })
        return true
    } catch(switchError) {
        log("[WALLET]: [addNetworkToMetamask]:[switch] " + switchError)
        if (switchError.code === 4902) {
            try {
                await ethereum.request({
                    method: 'wallet_addEthereumChain',
                    params: [
                        { 
                            chainId: rpc.chainId,
                            chainName: rpc.name,
                            nativeCurrency: {
                                name: 'AVAX',
                                symbol: 'AVAX',
                                decimals: 18
                            },
                            rpcUrls: [rpc.url],
                            blockExplorerUrls: [rpc.explorer]
                        }
                    ],
                })
            } catch(addError) {
                log("[WALLET]: [addNetworkToMetamask]:[add] " + addError)
            }
        }
        
        return false
    }
}

async function disconnect() {
    dispatchAccountUpdateEvent()
    log("[WALLET]: Wallet disconnected")
}

function loadPage() {
    // instead reload, dispatch event
    // window.location.reload()
    dispatchAccountUpdateEvent()
}

function dispatchAccountUpdateEvent() {
    const event = new Event('onAccountUpdate')
    document.dispatchEvent(event)
}

function dispatchProviderResetEvent() {
    const event = new Event('onProviderReset')
    document.dispatchEvent(event)
}

const isMetaMaskInstalled = () => {
    return Boolean(ethereum && ethereum.isMetaMask)
}

function log(msg) {
    console.log(msg)
}

export { 
    initialize, 
    isConnected, 
    walletAddress,
    hasInfiniteAllowance,
    connect,
    claim,
    disconnect,
    queryAvaxBalance,
    querySpiritBalance,
    queryClaimBalance,
    queryAllowance,
    queryLastClaimDate,
    queryClaimLock,
    queryAllowedClaimInterval,
    approveSpirit
}